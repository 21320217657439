<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="confirmTemplateModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="ConfirmTempRef"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content confirmTemplateModalContent">
          <div class="container">
            <div>
              <div class="container py-5 h-100">
                <div class="">
                  <div class="row mb-5">
                    <div
                      class="w-100 d-flex flex-column justify-content-between align-items-center"
                    >
                      <p class="Title">Are you sure ?</p>
                      <p class="TitleSub text-center">
                        All current Quiz data will be replaced with newly selected template.
                      </p>
                      <div
                        class="d-flex align-items-center w-100 justify-content-center"
                      >
                        <button
                          @click="updateTemplate"
                          :disabled="!SelectedId"
                          class="CreateBtn mr-5 outline-none text-white outline-none border-0 px-4 py-2"
                          style="background: #ea1601cc; border-radius: 6px"
                        >
                          <span v-if="!updateTempSpinner">Update</span>
                          <div
                            v-else
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                        <button
                          @click="cancelModal"
                          class="outline-none text-white outline-none border-0 px-3 py-2 see-demo-btn"
                          style="background: #4d1b7eaa; border-radius: 6px"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Actual Comp Start -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import $ from 'jquery'
export default {
  data() {
    return {
      updateTempSpinner: false,
      SelectedId : null
    };
  },
  computed: {
    ...mapGetters(["getQuizID"]),
  },
  methods: {
    CloseModal() {
      $("#confirmTemplateModal").modal("hide");
    },
    OpenModal(id) {
        this.SelectedId = id
      $("#confirmTemplateModal").modal("show");
    },
    cancelModal(){
        this.CloseModal()
        this.$emit("OpenTemp")
    },
    
     async updateTemplate() {
      this.updateTempSpinner = true;
      try {
        let data = {
          quizId: this.getQuizID,
          templateId: this.SelectedId,
        };
        const response = await axios.post(`changeQuizDesign`, data);
        if (response.status == 200 && response.data.status == "success") {
         
          this.CloseModal();
           this.$emit("OpenChooseTemp");
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.updateTempSpinner = false;
      }
    },
  },
};
</script>

<style scoped>
.confirmTemplateModalContent {
  border-radius: 20px;
  text-align: left;
}
.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  color: #4d4950;
}
.TitleSub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4d4950bb;
}
.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}
.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}
.w-90 {
  width: 90%;
}
</style>
